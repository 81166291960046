import { getMeta } from 'lambda-dom'
import bugsnag from '@bugsnag/js'
import { defaultTo } from 'ramda'
import { Environment } from '@app-enums'

export const bugsnagClient = getMeta('bugsnag-notifier-key')
    ? bugsnag.createClient({
        apiKey: defaultTo('', getMeta('bugsnag-notifier-key')),
        releaseStage: defaultTo(Environment.UNKNOWN, getMeta('environment')),
        enabledReleaseStages: [
            Environment.ACCEPTANCE,
            Environment.PRODUCTION,
        ],
    })
    : {
        notify: (...args: unknown[]) => console.log('Bugsnag placeholder:', ...args),
    }
