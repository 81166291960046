import { queryAll } from 'lambda-dom'

import { StateClass } from '@app-components'
import './mobile-header.bootstrapper'

export const desktopHeader = document.getElementById('site-header')
export const searchToggle = document.getElementById('header-search-toggle')
export const searchClose = document.getElementById('header-search-close')
export const searchField = document.getElementById('header-search-field')

export const banner = desktopHeader?.querySelector<HTMLElement>('.header--banner')
export const backdropImage = desktopHeader?.querySelector<HTMLElement>('.header--backdrop--image')
export const headerMain = desktopHeader?.querySelector<HTMLElement>('.header--main')
export const desktopHeaderLogo = desktopHeader?.querySelector<HTMLElement>('.header--branding')
export const desktopHeaderShareInfo = desktopHeader?.querySelector<HTMLElement>('.header--share-info')

const navItemsWithSub = desktopHeader
    ? queryAll<HTMLElement>('.header--nav--item.has-sub', desktopHeader)
    : null

headerMain?.addEventListener('mouseenter', () => desktopHeader?.classList?.add(StateClass.ACTIVE))
headerMain?.addEventListener('mouseleave', () => desktopHeader?.classList?.remove(StateClass.ACTIVE))

searchToggle?.addEventListener('click', () => {
    desktopHeader?.classList?.add(StateClass.SEARCH_ACTIVE)
    requestAnimationFrame(() => searchField?.focus())
})

searchClose?.addEventListener('click', () => desktopHeader?.classList?.remove(StateClass.SEARCH_ACTIVE))

// ------------------------------------------------------------------------------
//      Sub-nav toggling
// ------------------------------------------------------------------------------

let showDelay = 0
let timeout: number

export const setSubNavigationDelay = (delay: number) => {
    showDelay = delay
}

const responsiveItemWithSubHandler = (event: MouseEvent | Event): void => {
    if (! desktopHeader?.classList.contains(StateClass.SUB_ACTIVE)) {
        event.preventDefault()
    }
}

for (const itemWithSub of navItemsWithSub || []) {
    // eslint-disable-next-line @typescript-eslint/no-loop-func
    itemWithSub.addEventListener('mouseenter', () => {
        if (backdropImage) {
            backdropImage.style.backgroundImage = `url("${itemWithSub.dataset.subImage}")`
        }
        if (desktopHeader) {
            timeout = window.setTimeout(() => desktopHeader.classList.add(StateClass.SUB_ACTIVE), showDelay)
        }
    })

    // eslint-disable-next-line @typescript-eslint/no-loop-func
    itemWithSub.addEventListener('mouseleave', () => {
        window.clearTimeout(timeout)
        desktopHeader?.classList?.remove(StateClass.SUB_ACTIVE)
        if (backdropImage) {
            backdropImage.style.backgroundImage = ''
        }
    })

    if ('ontouchstart' in window) {
        itemWithSub.addEventListener('click', (event: MouseEvent) => {
            responsiveItemWithSubHandler(event)
        })

        itemWithSub.addEventListener('touchdown', (event: Event) => {
            responsiveItemWithSubHandler(event)
        })
    }
}
