import EventDelegation from '@jjwesterkamp/event-delegation'
import { isNil } from 'ramda'
import { Subject } from 'rxjs'

// ------------------------------------------------------------------------------
//      Hash link clicks
// ------------------------------------------------------------------------------
//
//      These clicks are registered for animated scroll handlers to ID-tagged
//      sections on the current page, but may as well be used for other purposes.
//      Emissions represent triggers (such as link clicks) to scroll to a certain
//      element on the current page.
//
const triggers$ = new Subject<{
    link: HTMLAnchorElement,
    event: MouseEvent,
    hashTarget: HTMLElement,
}>()

/**
 * Escape any potential search delimiter '?' for use in regex strings
 */
const escapeSearchDelimiter = (url: string): string => url.replace('?', '\\?').replace(/\+/g, '\\+')

// ------------------------------------------------------------------------------
//      Event listener (delegated)
// ------------------------------------------------------------------------------

EventDelegation
    .global()
    .events('click')
    .select('a')
    .listen((event) => {

        const currentUrl = location.origin + location.pathname + location.search

        // See if a string is the current url with a hash after it. The string will be the link href value.
        const regex = new RegExp(
            `${escapeSearchDelimiter(currentUrl)}#([\\w-]+)$`,
            'i',
        )

        // Return if the link is not the right format
        if (! regex.test(event.delegator.href)) {
            return
        }

        // Otherwise find an element with an id value matching the hash. If that's not found, return too.
        const hashValue = event.delegator.href.match(regex)![1]
        const hashTarget = document.getElementById(hashValue)

        if (isNil(hashTarget)) {
            return
        }

        triggers$.next({ hashTarget, link: event.delegator, event })
    })

/**
 * Stream of clicks on same-page links whose URL contains a hash reference to an ID-qualified element;
 * an element with an id HTML attribute. For each emission it is guaranteed that:
 * - The link URL is of the current page
 * - The link URL has a hash value to an existing element. The element is included in the returned
 *   object as `hashTarget`
 *
 * **Note:** Clicks on links to exactly the current URL (including hash) will be in this stream. That is
 * desirable because the user might already have scrolled away from the referenced element (and they
 * didn't click for us to do nothing :)
 *
 * **Note:** The events' `preventDefault` methods have not been called internally. `event` is included in the
 * returned object.
 */
export const hashLinkTriggers$ = triggers$.asObservable()
