import { fromEvent, Observable } from 'rxjs'
import { auditTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators'
import { always, cond, lt, pipe, prop } from 'ramda'

import { Breakpoint } from '@app-enums'
import { otherwise } from '@app-helpers'
import { WindowSizes } from '@app-types'

const getSizes = (): WindowSizes => ({
    width: window.innerWidth,
    height: window.innerHeight,
})

export const windowSizes$: Observable<WindowSizes> = fromEvent(window, 'resize').pipe(
    auditTime(40),
    map(() => getSizes()),
    startWith(getSizes()),
)

let prevWidth = window.innerWidth

export const windowWidth$: Observable<WindowSizes['width']> = fromEvent(window, 'resize').pipe(
    auditTime(40),
    filter(() => {
        let currWidth = window.innerWidth
        let widthChanged = currWidth !== prevWidth
        prevWidth = currWidth
        return widthChanged
    }),
    map(() => getSizes().width),
    startWith(getSizes().width),
)

export const windowBreakpoints$ = windowSizes$.pipe(
    map<WindowSizes, Breakpoint>(pipe(prop('width'), cond([
        [lt(Breakpoint.SM), always(Breakpoint.XS)],
        [lt(Breakpoint.MD), always(Breakpoint.SM)],
        [lt(Breakpoint.LG), always(Breakpoint.MD)],
        [lt(Breakpoint.XL), always(Breakpoint.LG)],
        [otherwise, always(Breakpoint.XL)],
    ]))),
    distinctUntilChanged(),
)

windowSizes$.subscribe(({ height }) => document.documentElement.style.setProperty('--100vh', `${height}px`))

export type BreakPoint = 'XS' | 'MIN' | 'SM' | 'MD' | 'LG' | 'XL' | 'XXL' | 'XXXL';

export const getWindowBreakPointForNewsroom = (): BreakPoint => {
    const width = getSizes().width

    if (width > Breakpoint.XS && width <= Breakpoint.MIN) return 'XS'
    if (width > Breakpoint.MIN && width <= Breakpoint.SM) return 'MIN'
    if (width > Breakpoint.SM && width <= Breakpoint.MD) return 'SM'
    if (width > Breakpoint.MD && width <= Breakpoint.LG) return 'MD'
    if (width > Breakpoint.LG && width <= Breakpoint.XL) return 'LG'
    if (width > Breakpoint.XL && width <= Breakpoint.XXL) return 'XL'
    if (width > Breakpoint.XXL && width <= Breakpoint.XXXL) return 'XXL'

    return 'XXXL'
}
