import { display, hide } from 'lambda-dom'
import { isNil, not } from 'ramda'

export function visuallyHide(element: HTMLElement | null): void {
    if (isNil(element)) {
        return console.warn('Cannot hide null or undefined Element reference')
    }
    element.style.visibility = 'hidden'
}

export function visuallyShow(element: HTMLElement | null): void {
    if (isNil(element)) {
        return console.warn('Cannot hide null or undefined Element reference')
    }
    element.style.visibility = 'visible'
}

/**
 * Shows given element if `cond` is truthy, otherwise given element is being hidden.
 */
export function showIf(cond: boolean, element: HTMLElement, displayValue: string | null = null): void {
    if (cond) {
        display(displayValue)(element)
    } else {
        hide(element)
    }
}

// ------------------------------------------------------------------------------
//      Element class helpers
// ------------------------------------------------------------------------------

/**
 * Adds given className(s) to given element if `cond` is truthy. Otherwise, the classes are removed from given
 * element's classList. Takes either a string or array of strings for a single and multiple classes respectively.
 */
export function haveClassIf(cond: boolean, element: HTMLElement, classes: string | string[]): void {

    const classArray = Array.isArray(classes) ? classes : [classes]

    if (cond) {
        element.classList.add(...classArray)
    } else {
        element.classList.remove(...classArray)
    }
}

export function haveClassUnless(cond: boolean, element: HTMLElement, classes: string | string[]): void {
    haveClassIf(not(cond), element, classes)
}

/**
 * Queries up the DOM for given `selector`, starting from given `leafElement`.
 * The first element found matching `selector` will be returned.
 * Querying will stop as soon as given `root` is encountered.
 * If no matching element was found, `null` is returned.
 *
 * Based on the {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill MDN `closest` polyfill}
 *
 * @param leaf     The innermost element in the DOM tree to start searching from.
 * @param selector The selector (CSS style) to match ancestor elements with.
 * @param root     The element that acts as a scope for the query.
 */
export function closestWithin(leaf: HTMLElement, selector: string, root: Node): HTMLElement | null {

    if (typeof leaf.closest === 'function') {
        const closest = leaf.closest<HTMLElement>(selector)

        if (closest === null || ! root.contains(closest)) {
            return null
        }

        return closest
    }

    if (! root.contains(leaf)) {
        return null
    }

    let current: HTMLElement | null = leaf

    do {
        if (current.matches(selector)) {
            return current
        }

        current = current.parentElement
    } while (current !== null && current !== root && current.nodeType === Node.ELEMENT_NODE)

    return null
}

/**
 * Load a script as an old-skool `<script>` tag.
 *
 * @param {string} src
 * @return {Promise<HTMLScriptElement>}
 */
export const loadScript = (src: string): Promise<HTMLScriptElement> => new Promise((resolve, reject) => {
    const script = document.createElement('script')

    script.src = src
    script.onload = () => resolve(script)
    script.onerror = () => reject(script)

    document.head.appendChild(script)
})

export const DOMReadyP = new Promise<Event | void>((resolve) => {
    if (document.readyState !== 'loading') {
        resolve()
    } else {
        window.addEventListener('DOMContentLoaded', resolve)
    }
})
