export function noop(): void {
}

export const otherwise = () => true

export const parseBase10 = (x: string): number => parseInt(x, 10)

export const delay = (ms: number) => new Promise<void>((resolve) => setTimeout(resolve, ms))

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
