import EventDelegation from '@jjwesterkamp/event-delegation'
import { queryOneWithin } from 'lambda-dom'

const videoElement = document.getElementById('video-component')

if (videoElement) {
    const queryFn = queryOneWithin(videoElement)

    const video: HTMLVideoElement | null = queryFn('video.video-component__video')
    const button: HTMLDivElement | null = queryFn('div.video-component__button')
    const image: HTMLDivElement | null = queryFn('div.video-component__image')

    if (video && button && image) {
        const resetVideo = () => {
            image.classList.add('active')
            video.classList.remove('active')
            video.pause()
            video.currentTime = 0
        }

        EventDelegation.global().events('click').select('[data-play]').listen(() => {
            video.classList.add('active')
            image.classList.remove('active')
            setTimeout(() => video.play(), 500)
        })

        EventDelegation.global().events('click').select('[data-video]').listen(() => resetVideo())
        video.addEventListener('ended', () => resetVideo(), false)
    }
}
