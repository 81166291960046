import { isNil, pipe } from 'ramda'
import { getMeta } from 'lambda-dom'

import { parseBase10 } from '@app-helpers'
import { StateClass } from '@app-components'

const desktopHeader = document.getElementById('site-header')
const banner = desktopHeader?.querySelector<HTMLElement>('.header--banner')

// ------------------------------------------------------------------------------
//      Lib
// ------------------------------------------------------------------------------


/**
 * Dismisses the notification bar: hides it, and sets a localstorage entry to remember that preference.
 */
const dismissBanner = () => window.localStorage.setItem('banner-dismissed', banner?.dataset?.timestamp!)

/**
 * Just displays the notification bar. Does not interact with the localstorage API.
 */
const displayBanner = (display: boolean) => desktopHeader?.classList?.toggle(StateClass.BANNER_ACTIVE, display)

/**
 * Gets the UNIX timestamp from localstorage denoting the previous notification dismissal.
 * Returns null if the value is not set.
 */
const getDismissedTimestamp = (): number | null => {
    const stringValue = window.localStorage.getItem('banner-dismissed')
    return isNil(stringValue) ? null : parseBase10(stringValue)
}

/**
 * Gets the timestamp of the current (latest) notification content. This timestamp tells
 * when the current message was created or was most recently edited.
 */
const getBannerTimestamp = (): number | null => {
    const stringValue = banner?.dataset?.timestamp
    return isNil(stringValue) ? null : parseBase10(stringValue)
};

// ------------------------------------------------------------------------------
//      Runtime
// ------------------------------------------------------------------------------

(() => {
    const bannerActive = getMeta('banner-active', pipe(Number, Boolean))

    if (! bannerActive) {
        return
    }

    const dismissedTimestamp = getDismissedTimestamp()
    const bannerTimestamp = getBannerTimestamp()

    if (bannerTimestamp === null) {
        return
    }

    if (dismissedTimestamp !== null && dismissedTimestamp >= bannerTimestamp) {
        return
    }

    window.localStorage.removeItem('banner-dismissed')

    const detailsButton = banner?.querySelector('#banner-proceed')
    const dismissButton = banner?.querySelector('#banner-close')

    if (isNil(detailsButton) || isNil(dismissButton)) {
        return
    }

    displayBanner(true)

    detailsButton.addEventListener('click', dismissBanner)
    dismissButton.addEventListener('click', () => {
        dismissBanner()
        displayBanner(false)
    })
})()
