import { addClasses, queryAll, removeClasses, touchElement } from 'lambda-dom'
import { juxt } from 'ramda'

import { DOMReadyP } from '@app-helpers'

enum ImageLoadingState {
    LOADING = 'loading',
    LOADED = 'loaded',
    FAILED = 'failed',
}

/**
 * Adds the appropriate classes to given image-container to reflect the
 * state of image loading success.
 */
const setLoaded = juxt([
    addClasses(
        ImageLoadingState.LOADED,
    ),
    removeClasses(
        ImageLoadingState.LOADING,
        ImageLoadingState.FAILED,
    ),
])

/**
 * Adds the appropriate classes to given image-container to reflect the
 * state of image loading failure.
 */
const setFailed = juxt([
    addClasses(
        ImageLoadingState.FAILED,
    ),
    removeClasses(
        ImageLoadingState.LOADING,
        ImageLoadingState.LOADED,
    ),
])

/**
 * Load the dynamic, async image components for given scope. If the scope
 * is omitted, will load images for the entire document.
 */
export const loadImages = (scope: ParentNode = document): void => {
    for (const container of queryAll<HTMLElement>('.image-container', scope)) {

        touchElement<HTMLImageElement>('.image-container--image', (img) => {

            const onload = () => {
                setLoaded(container)
                img.removeEventListener('load', onload)
                img.removeEventListener('error', onerror)
            }

            const onerror = () => {
                setFailed(container)
                img.removeEventListener('load', onload)
                img.removeEventListener('error', onerror)
            }

            const observer = new IntersectionObserver(([entry]) => {

                if (entry.intersectionRatio === 0) {
                    return
                }

                img.addEventListener('load', onload)
                img.addEventListener('error', onerror)

                img.src = img.dataset.src || ''
                observer.unobserve(container)
            }, { threshold: [0] })

            observer.observe(container)

        }, container)
    }
}

DOMReadyP.then(() => loadImages())
